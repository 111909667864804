<template>
  <div class="container-md col-lg-11 col-md-11 review-activity pt-2">
    <div class="d-flex">
      <h4 class="text-primary w-100">
        {{ "Candidate Interview" }}
      </h4>
      <CButton
        class="justify-content-end text-nowrap"
        color="primary"
        shape="pill"
        @click="backToList"
        >Back to list</CButton
      >
    </div>
    <div class="mt-3 mb-3">
      <InterviewActivity
        v-if="isReadytoInterview"
        :activityDetails="selectedActivity"
        @openCandidateInfoModal="openCandidateInfoModal"
        @openJobInfoModal="openJobInfoModal"
      />
    </div>
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      :isShowContact="false"
      @modalCallBack="candidateInfoModalCallBack"
    />
    <JobInfoModal
      v-if="jobInfoModal.isShowPopup"
      :job_id="jobInfoModal.job_id"
      :isShowModal="jobInfoModal.isShowPopup"
      @modalCallBack="jobInfoModalCallBack"
    />
  </div>
</template>
<script>
import ScheduleActivity from "@/containers/ActivityList/Candidate/ScheduleActivity";
import InterviewActivity from "@/containers/ActivityList/Candidate/InterviewActivity";
import { mapActions, mapGetters } from "vuex";
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import JobInfoModal from "@/components/reusable/JobInfoModal";
export default {
  components: {
    ScheduleActivity,
    InterviewActivity,
  },
  data() {
    return {
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
      jobInfoModal: {
        isShowPopup: false,
        job_id: null,
      },
    };
  },
  computed: {
    ...mapGetters(["selectedActivity"]),
    isReadytoInterview() {
      return (
        (this.selectedActivity?.status_id === 54 &&
          this.selectedActivity?.sub_status_id === 55) ||
        false
      );
    },
  },
  methods: {
    ...mapActions(["fetchSelectedJobActivity"]),
    backToList() {
      this.$router.push({
        path: `/home?tab=5`,
      });
    },
    openCandidateInfoModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    openJobInfoModal(job_id) {
      if (job_id) {
        this.jobInfoModal.job_id = job_id;
        this.jobInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    jobInfoModalCallBack(action) {
      this.jobInfoModal.isShowPopup = false;
    },
  },
  async mounted() {
    const {
      params: { jobID, candidateID },
    } = this.$route;
    let job_id = jobID ? parseInt(jobID) : null;
    let candidate_uid = candidateID ? parseInt(candidateID) : null;
    this.fetchSelectedJobActivity({
      job_id,
      candidate_uid,
      action_id: 18,
      status_id__in: [54],
      sub_status_id__in: [55],
      completed_by__in: ["null"],
    });
  },
};
</script>